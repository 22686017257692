import "../core/index";

class ConnexeaseCRM {
	core;
	originalDataLayerPushEvent;
	originalReMarketingAddFn;
	memberInformation;

	constructor(publicToken) {
		this.core = new window.RegisteredConnexeaseModules.Core(publicToken, {
			debugEnabled: true,
		});
		this.originalDataLayerPushEvent = window.dataLayerPushEvent;
		this.originalReMarketingAddFn = window.cart.remarketing.add;
	}

	get provider() {
		return this.core.providers.TICIMAX;
	}

	get isTicimax() {
		// const ticimaxPreConnectElement = document.head.querySelector(
		// 	'link[rel="preconnect"]',
		// );

		// return ticimaxPreConnectElement?.href?.includes("cdn.ticimax.cloud");

		return true;
	}

	async watch() {
		if (this.core.isDebugEnabled) {
			console.log("[CN-CRM] [DEBUG] Watching Ticimax SDK...");
		}

		if (this.isTicimax === false) {
			console.warn("[CN-CRM] Ticimax SDK is not detected on this page.");
			return;
		}

		const member = await this.member();

		if (!member) {
			return;
		}

		window.dataLayerPushEvent = async (...event) => {
			await this.saveCartSnapshot(1000);

			this.originalDataLayerPushEvent(...event);
		};

		if (this.core.path.toString().toLowerCase().includes("siparistamamlandi")) {
			const products = (window?.purchaseProducts ?? []).map((rawProduct) =>
				this.mapProduct(rawProduct),
			);

			const total = window?.siparisTutar?.toString() ?? null;
			const orderNumber = window?.siparisNo?.toString() ?? null;
			const payload = {
				total: {
					cartTotal: total,
					orderNumber,
				},
			};

			this.saveEvent(products, this.core.eventTypes.CHECKOUT, payload);
		}

		if (this.core.path.toString().toLowerCase().includes("sepetim")) {
			await this.saveCartSnapshot();
		}

		window.cart.remarketing.add = async () => {
			await this.saveCartSnapshot(1000);

			await this.originalReMarketingAddFn();
		};

		if (this.core.path === "/") {
			await this.saveCartSnapshot();
		}

		if (this.core.path?.toString().toLowerCase().includes("hesabim")) {
			await this.saveCartSnapshot();
		}

		if (this.core.path?.toString().toLowerCase().includes("siparistamamla")) {
			await this.saveCartSnapshot();
		}

		if (this.core.path?.toString().toLowerCase().includes("odeme")) {
			await this.saveCartSnapshot();
		}
	}

	formatCartTotal(input = "") {
		if (typeof input !== "string") {
			return null;
		}

		const value = input.replaceAll(".", "").replaceAll(",", ".");

		const regex = /[^0-9.]/g;
		const cleanedValue = value.replace(regex, "");

		return cleanedValue;
	}

	async member() {
		try {
			const response = await fetch("/api/member/GetMember", {
				method: "GET",
			}).then((response) => response.json());

			if (response?.isError === true) {
				console.warn("[CN-CRM] Ticimax SDK member not found.");
				return;
			}

			if (response === undefined) {
				console.warn("[CN-CRM] Ticimax SDK member not found.");
				return;
			}

			this.memberInformation = response;

			return response;
		} catch (error) {
			console.error("[CN-CRM] Ticimax SDK member error:", error);
		}
	}

	get memberPayload() {
		return {
			userId: this.memberInformation?.id.toString() ?? null,
			userEmail: this.memberInformation?.eMail ?? null,
			userPhone: this.memberInformation?.cellPhone ?? null,
			provider: this.provider,
			website: this.core.host,
		};
	}

	getMemberPayload(response) {
		return {
			userId: response?.id.toString() ?? null,
			userEmail: response?.eMail ?? null,
			userPhone: response?.cellPhone ?? null,
			provider: this.provider,
			website: this.core.host,
		};
	}

	async saveCartSnapshot(ms = 0) {
		setTimeout(async () => {
			const cart = await this.memberCart();

			if (!cart) {
				return;
			}

			const products =
				cart?.products?.map((rawProduct) => ({
					productId: rawProduct?.productId?.toString(),
					variantId: rawProduct?.variantId?.toString(),
					name: rawProduct?.productName,
					price: this.formatCartTotal(
						rawProduct?.productCartPriceVatIncludedStr?.toString(),
					),
					totalPrice: this.formatCartTotal(
						rawProduct?.productTotalPriceVatIncludedStr?.toString(),
					),
					brand: rawProduct?.brand,
					category: rawProduct?.categori,
					quantity: rawProduct?.piece,
				})) ?? [];

			let cartTotal = "0";

			if (products?.length > 0) {
				cartTotal = this.formatCartTotal(
					cart?.subTotalVatIncludedStr?.toString(),
				);
			}

			const payload = {
				total: {
					cartTotal,
				},
			};

			this.saveEvent(products, this.core.eventTypes.CART_SNAPSHOT, payload);
		}, ms);
	}

	async memberCart() {
		try {
			const response = await fetch("/api/cart/GetMemberCart", {
				method: "GET",
			}).then((response) => response.json());

			if (response?.isError === true) {
				console.warn("[CN-CRM] Ticimax SDK cart not found.");
				return;
			}

			if (response === undefined) {
				console.warn("[CN-CRM] Ticimax SDK cart not found.");
				return;
			}

			return response?.cart;
		} catch (error) {
			console.error("[CN-CRM] Ticimax SDK cart error:", error);

			return;
		}
	}

	saveEvent(mappedProduct, eventType, payload = {}) {
		const hasCartTotal =
			payload?.total?.cartTotal !== undefined &&
			payload?.total?.cartTotal !== null;

		const eventBody = {
			...this.memberPayload,
			...(hasCartTotal ? { cartTotal: payload.total.cartTotal } : {}),
			event: eventType,
			...(Array.isArray(mappedProduct)
				? { products: mappedProduct }
				: { product: mappedProduct }),
			...payload,
		};

		this.core.saveEvent(eventType, eventBody);
	}

	mapProduct(product = {}) {
		return {
			productId: product?.kartid?.toString() ?? null,
			variantId: product?.urunid?.toString() ?? null,
			name: product?.urunName ?? null,
			price: product?.tutar?.toString() ?? null,
			brand: product?.urunMarka ?? null,
			category: product?.urunKategori ?? null,
			quantity: product?.urunAdet ?? null,
		};
	}
}

window.RegisteredConnexeaseModules = {
	...window.RegisteredConnexeaseModules,
	ConnexeaseCRM,
};
